export interface MingleRadioButtonProps {
  label: string;
  disabled?: boolean;
  value: string;
  checked: boolean;
  name: string;
  showRadio?: boolean;
  isLarge?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const MingleRadioButton = (props: MingleRadioButtonProps) => {
  return (
    <div className="form-control w-full ">
      <label
        className={`label w-full flex  border min-h-12 border-gray-300 text-gray-800 dark:text-gray-200  focus:border-green-500 focus:outline-offset-0 focus:outline-green-200 focus:outline-4 rounded-md p-2 cursor-pointer ${
          props.checked
            ? "border-green-600 bg-green-50 text-green-700 dark:bg-green-800 dark:text-green-100"
            : ""
        } ${props.isLarge ? "text-2xl font-[700] px-8 py-4 " : " px-4 py-2 "}`}
      >
        <span>{props.label}</span>
        <input
          type="radio"
          id={props.name}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          className={`radio radio-secondary dark:radio-accent ml-2 radio-sm checked:border-green-500 checked:bg-green-500 ml-auto ${
            props.showRadio ? "" : "hidden"
          }
           ${props.checked ? " border-green-5000 bg-green-500" : ""}`}
          defaultChecked={props.checked}
        />
      </label>
    </div>
  );
};

export default MingleRadioButton;
